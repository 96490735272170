
import EditAssetView from './EditAssetView';
import { useState, useEffect, useRef } from 'react';
import { Card, Modal, Spinner } from 'react-bootstrap';

import '../../../styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const reportTypes = ["Purchase Report", "Filtered Asset List"];
// *** For testing *** 
// const reportServiceURL = 'http://localhost:4001';
// const managerGql = 'http://localhost:4000/graphql'

// *** For deploy *** 
const reportServiceURL = 'https://asset-report-node-service-bjudfyf5za-uc.a.run.app'
const managerGql = 'https://asset-employee-gql-bjudfyf5za-uc.a.run.app/graphql'

const AssetManagerInterface = () => {
    /* Consts & UseStates */
    const [selectedAssetInformation, setSelectedAssetInformation] = useState({
        assetID: "",
        assetImageID: "",
        purchaseDate: "",
        vendor: "",
        assetDescription: "",
        cost: "",
        serialNumber: "",
        invoiceNumber: "",
        PONumber: "",
        division: "",
        purchaseStatus: "",
        purpose: "",
        purchasedBy: "",
        purchasedByName: "",
        approvedBy: "",
        approvedByName: "",
        photo: "",
        assignmentLog: "",
        useStatus: "",
        assignedTo: "",
        assetType: "",
        location: "",
        assetNumber: "",
        checkOutDate: "",
        newAssignment: {
            assigneeName: "",
            assigneeID: "",
            assigneeEmail: "",
            managerEmail: "",
            assigneeDivisionID: "",
            assigneeDivisionName: "",
            checkOutDate: "",
            checkInDate: "",
            checkOutCondition: "",
            checkInCondition: "",
            checkOutConditionString: "",
            checkInConditionString: "",
            checkOutNotes: "",
            checkInNotes: "",
            assignmentDirection: ""
        }
    });
    const [selectedAssetInfoUnedited, setSelectedAssetInfoUnedited] = useState({
        assetID: "",
        assetImageID: "",
        purchaseDate: "",
        vendor: "",
        assetDescription: "",
        cost: "",
        serialNumber: "",
        invoiceNumber: "",
        PONumber: "",
        division: "",
        purchaseStatus: "",
        purpose: "",
        purchasedBy: "",
        purchasedByName: "",
        approvedBy: "",
        approvedByName: "",
        photo: "",
        assignmentLog: "",
        useStatus: "",
        assignedTo: "",
        assetType: "",
        location: "",
        assetNumber: "",
        checkOutDate: "",
        newAssignment: {
            assigneeName: "",
            assigneeID: "",
            checkOutDate: "",
            checkInDate: "",
            checkOutCondition: "",
            checkInCondition: "",
            checkOutConditionString: "",
            checkInConditionString: "",
            checkOutNotes: "",
            checkInNotes: "",
            assignmentDirection: ""
        }
    })
    // const [editingMode, setEditingMode] = useState(false);
    const [creatingNewAsset, setCreatingNewAsset] = useState(false);
    const [selCardIndex, setSelCardIndex] = useState('');
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [filters, setFilters] = useState({
        division: "",
        assignment: "",
        assignmentString: "",
        purchaseStatus: "",
        activeStatus: "",
        assetType: "",
        searchString: ""
    })
    // Copy of filters before changes, apply in case of cancel
    const [filtersUnedited, setFiltersUnedited] = useState({
        division: "",
        assignment: "",
        assignmentString: "",
        purchaseStatus: "",
        activeStatus: "",
        assetType: "",
        searchString: ""
    });
    const [filterCombine, setFilterCombine] = useState("");
    const [pendingChanges, setPendingChanges] = useState(false);
    const [selReportType, setSelReportType] = useState('');
    const [loadingPdfPreview, setLoadingPdfPreview] = useState(false);
    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [pdf, setPdf] = useState('');
    const [divisionList, setDivisionList] = useState([]);
    const [assetTypeList, setAssetTypeList] = useState([]);
    const [assetList, setAssetList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [conditionsList, setConditionsList] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [peopleList, setPeopleList] = useState([]);
    const [checkInLocationList, setCheckInLocationList] = useState([]);
    const [checkOutLocationList, setCheckOutLocationList] = useState([]);
    const [allLocationList, setAllLocationList] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const [checkInList, setCheckInList] = useState([]);
    const [allPeopleLocationsList, setAllPeopleLocationsList] = useState([]);
    const [newImageB64, setNewImageB64] = useState(false);
    const [assetAssignments, setAssetAssignments] = useState([]);
    const fileInput = useRef()
    const [loadingCard, setLoadingCard] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingAssets, setLoadingAssets] = useState(false);
    const [removePhoto, setRemovePhoto] = useState(false);




    let s = [];

    // For Save Changes modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => { if (pendingChanges) { setShow(true) } };

    /* Handlers */
    // Handle updating the information for changing fields
    const handleOnChangeSelAssetInfo = (e) => {
        setPendingChanges(true);
        e.preventDefault();
        const value = e.target.value;
        console.log(e.target)

        switch (e.target.id) {
            // OnChange for Asset Info
            case "purchase-date":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, purchaseDate: value }
                });
                break;
            case "vendor":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, vendor: value }
                });
                break;
            case "asset-description":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, assetDescription: value }
                });
                break;
            case "cost":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, cost: value }
                });
                break;
            case "serial-number":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, serialNumber: value }
                });
                break;
            case "po-number":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, PONumber: value }
                });
                break;

            case "invoice-number":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, invoiceNumber: value }
                });
                break;

            case "division":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, division: value }
                });
                break;
            case "purpose":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, purpose: value }
                });
                break;
            case "purchased-by":
                const pbIndex = e.nativeEvent.target.selectedIndex;
                const pbName = e.nativeEvent.target[pbIndex].text;
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        purchasedBy: value,
                        purchasedByName: pbName
                    }
                });
                break;
            case "approved-by":
                const abIndex = e.nativeEvent.target.selectedIndex;
                const abName = e.nativeEvent.target[abIndex].text;
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        approvedBy: value,
                        approvedByName: abName
                    }
                });
                break;
            case "check-in-date":
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        newAssignment: {
                            ...prevState.newAssignment, checkInDate: value
                        }
                    }
                });
                break;
            case "check-out-date":
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        newAssignment: {
                            ...prevState.newAssignment, checkOutDate: value
                        }
                    }
                });
                break;
            case "asset-type":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, assetType: value }
                });
                break;
            case "check-in-condition":
                const cicIndex = e.nativeEvent.target.selectedIndex;
                const cicString = e.nativeEvent.target[cicIndex].text;
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        newAssignment: {
                            ...prevState.newAssignment,
                            checkInCondition: value,
                            checkInConditionString: cicString
                        }
                    }
                });
                break;
            case "check-out-condition":
                const cocIndex = e.nativeEvent.target.selectedIndex;
                const cocString = e.nativeEvent.target[cocIndex].text;
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        newAssignment: {
                            ...prevState.newAssignment,
                            checkOutCondition: value,
                            checkOutConditionString: cocString
                        }
                    }
                });
                break;
            case "check-in-notes":
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        newAssignment: {
                            ...prevState.newAssignment, checkInNotes: value
                        }
                    }
                });
                break;
            case "check-out-notes":
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        newAssignment: {
                            ...prevState.newAssignment, checkOutNotes: value
                        }
                    }
                });
                break;
            case "assignment-log":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, assignmentLog: value }
                });
                break;
            case "new-assignee-in":
                const inIndex = e.nativeEvent.target.selectedIndex;
                const inName = e.nativeEvent.target[inIndex].text;
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        newAssignment: {
                            ...prevState.newAssignment,
                            assigneeID: value,
                            assigneeName: inName,
                        }
                    }
                });
                break;
            case "new-assignee-out":
                const outIndex = e.nativeEvent.target.selectedIndex;
                const outName = e.nativeEvent.target[outIndex].text;
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        newAssignment: {
                            ...prevState.newAssignment,
                            assigneeID: value,
                            assigneeName: outName
                        }
                    }
                });
                break;
            default:
                break;
        }
    }
    const handleOnChangeFiltersReports = (e) => {
        e.preventDefault();
        const value = e.target.value;
        console.log(value)
        switch (e.target.id) {
            // OnChange for Filters
            case "filter-division":
                setFilters(prevState => {
                    return { ...prevState, division: value }
                })
                break;
            case "filter-assignment":
                const aIndex = e.nativeEvent.target.selectedIndex;
                const aName = e.nativeEvent.target[aIndex].text;
                setFilters(prevState => {
                    return {
                        ...prevState,
                        assignment: value,
                        assignmentString: aName
                    }
                })
                break;
            case "report-type":
                setSelReportType(value)
                break;
            case "filter-asset-type":
                setFilters(prevState => {
                    return { ...prevState, assetType: value }
                })
                break;
            case "filter-search-bar":
                setFilters(prevState => {
                    return { ...prevState, searchString: value }
                })
                break;
            default:
                break;
        }
    }


    // Set which asset is selected - put information in form
    async function handleUpdateSelectedAsset(asset) {
        setLoadingCard(true)
        // If no pending changes, and not creating new, we selected a new card, so want all new information
        if (!pendingChanges && !creatingNewAsset) {
            setNewImageB64(false)
            setSelCardIndex(asset.Asset_ID)
            const getAssetInfoQuery = `
            {
                getAssetInfo(Asset_ID:"${asset.Asset_ID}"){
                  Asset_ID
                  AssetImage_ID
                  AssetNumber_IN
                  Description_VC
                  PurchaseDate_DT
                  Vendor_ID
                  Vendor_VC
                  AssetType_ID
                  AssetType_VC
                  Cost_NU
                  SerialNumber_VC
                  InvoiceNumber_VC
                  PONumber_VC
                  Division_ID
                  Division_VC
                  PurchaseApproved_BT
                  Purpose_VC
                  PBFirstName_VC
                  PBLastName_VC
                  PBEmail_VC
                  PBPeople_ID
                  ABFirstName_VC
                  ABLastName_VC
                  ABEmail_VC
                  ABPeople_ID
                  Active_BT
                  Location_ID
                  Location_VC
                  ATPFirstName_VC
                  ATPLastName_VC
                  ATPEmail_VC
                  ATPPeople_ID
                  ImagePath_VC
                  AIActive_BT
                }
              }
            `;
            const getAssetInfoFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getAssetInfoQuery }), headers: { 'Content-Type': 'application/json' } });
            const getAssetInfoJson = await getAssetInfoFetch.json();
            console.log('assetInfoQueryResponse: ', getAssetInfoJson.data.getAssetInfo);
            const assetDetails = getAssetInfoJson.data.getAssetInfo[0]

            console.log('details', assetDetails)

            const getAssetAssignmentsQuery = `
            {
                getAssetAssignmentsByAssetID(Asset_ID:"${asset.Asset_ID}"){
                  Asset_ID
                  AssetAssignment_ID
                  Assigned_BT
                  People_ID
                  Condition_ID
                  Condition_VC
                  CheckInOut_DT
                  FirstName_VC
                  LastName_VC
                  Location_VC
                  Location_ID
                  Notes_VC
                }
            }
            `;
            console.log(getAssetAssignmentsQuery)
            const getAssetAssignmentsFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getAssetAssignmentsQuery }), headers: { 'Content-Type': 'application/json' } });
            const getAssetAssignmentsJson = await getAssetAssignmentsFetch.json();
            console.log('getAssetAssignmentsQueryResponse: ', getAssetAssignmentsJson.data.getAssetAssignmentsByAssetID);
            setAssetAssignments(getAssetAssignmentsJson.data.getAssetAssignmentsByAssetID)

            let formattedPurchaseDate = ''
            if (assetDetails.PurchaseDate_DT) {
                const purchaseDate = assetDetails.PurchaseDate_DT
                if (purchaseDate && purchaseDate !== undefined && purchaseDate !== "" && purchaseDate.includes("T")) {
                    const purchaseDateArr = purchaseDate.split('T');
                    formattedPurchaseDate = purchaseDateArr[0];
                } else {
                    formattedPurchaseDate = purchaseDate
                }
            }

            // const checkOutDate = assetDetails.CheckDate_DT
            // let formattedCheckOutDate = ''
            // if (checkOutDate && checkOutDate !== undefined && checkOutDate !== "" && checkOutDate.includes("T")) {
            //     const checkOutDateArr = checkOutDate.split('T');
            //     formattedCheckOutDate = checkOutDateArr[0];
            // } else {
            //     formattedCheckOutDate = purchaseDate
            // }

            let formattedCost = ''
            if (assetDetails.Cost_NU && assetDetails.Cost_NU !== undefined && assetDetails.Cost_NU !== "") {
                const cost = assetDetails.Cost_NU
                formattedCost = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(cost);
                console.log('formattedCost', formattedCost)
            }

            let purchaseApproved = ''
            if (assetDetails.PurchaseApproved_BT !== "" && assetDetails.PurchaseApproved_BT !== null && (assetDetails.PurchaseApproved_BT === "true" || assetDetails.PurchaseApproved_BT === 1)) {
                purchaseApproved = '1'
            } else if (assetDetails.PurchaseApproved_BT !== "" && assetDetails.PurchaseApproved_BT !== null && (assetDetails.PurchaseApproved_BT === "false" || assetDetails.PurchaseApproved_BT === 0)) {
                purchaseApproved = '0'
            } else {
                purchaseApproved = assetDetails.PurchaseApproved_BT
            }

            let useStatus = ''
            if (assetDetails.Active_BT !== "" && assetDetails.Active_BT !== null && (assetDetails.Active_BT === "true" || assetDetails.Active_BT === 1)) {
                useStatus = '1'
            } else if (assetDetails.Active_BT !== "" && assetDetails.Active_BT !== null && (assetDetails.Active_BT === "false" || assetDetails.Active_BT === 0)) {
                useStatus = '0'
            } else {
                useStatus = assetDetails.Active_BT
            }

            let assetPhoto = ''
            let assetPhotoID = ''
            if (assetDetails.AIActive_BT === 1 || assetDetails.AIActive_BT === 'true') {
                assetPhoto = assetDetails.ImagePath_VC
                assetPhotoID = assetDetails.AssetImage_ID
            }

            let pbName = ''
            let abName = ''
            if (assetDetails.ABPeople_ID && assetDetails.ABPeople_ID !== null && assetDetails.ABFirstName_VC && assetDetails.ABFirstName_VC !== null && assetDetails.ABLastName_VC && assetDetails.ABLastName_VC !== null) {
                abName = `${assetDetails.ABFirstName_VC} ${assetDetails.ABLastName_VC}`
            }
            if (assetDetails.PBPeople_ID && assetDetails.PBPeople_ID !== null && assetDetails.PBFirstName_VC && assetDetails.PBFirstName_VC !== null && assetDetails.PBLastName_VC && assetDetails.PBLastName_VC !== null) {
                pbName = `${assetDetails.PBFirstName_VC} ${assetDetails.PBLastName_VC}`
            }

            setSelectedAssetInformation({
                assetID: assetDetails.Asset_ID,
                assetImageID: assetPhotoID || "",
                purchaseDate: formattedPurchaseDate || "",
                vendor: assetDetails.Vendor_ID || "",
                assetDescription: assetDetails.Description_VC || "",
                cost: formattedCost || "",
                serialNumber: assetDetails.SerialNumber_VC || "",
                invoiceNumber: assetDetails.InvoiceNumber_VC || "",
                PONumber: assetDetails.PONumber_VC || "",
                division: assetDetails.Division_ID || "",
                purchaseStatus: purchaseApproved || "",
                purpose: assetDetails.Purpose_VC || "",
                purchasedBy: assetDetails.PBPeople_ID || "",
                purchasedByName: pbName || "",
                approvedBy: assetDetails.ABPeople_ID || "",
                approvedByName: abName || "",
                photo: assetPhoto || "",
                useStatus: useStatus || "",
                assignedTo: assetDetails.ATPPeople_ID || "",
                assetType: assetDetails.AssetType_ID || "",
                location: "",
                assetNumber: "",
                checkOutDate: "",
                newAssignment: {
                    assigneeName: "",
                    assigneeID: "",
                    assigneeEmail: "",
                    managerEmail: "",
                    assigneeDivisionID: "",
                    assigneeDivisionName: "",
                    checkOutDate: "",
                    checkInDate: "",
                    checkOutCondition: "",
                    checkInCondition: "",
                    checkOutConditionString: "",
                    checkInConditionString: "",
                    checkOutNotes: "",
                    checkInNotes: "",
                    assignmentDirection: ""
                }
            })
            setSelectedAssetInfoUnedited({
                assetID: assetDetails.Asset_ID,
                assetImageID: assetPhotoID || "",
                purchaseDate: formattedPurchaseDate || "",
                vendor: assetDetails.Vendor_ID || "",
                assetDescription: assetDetails.Description_VC || "",
                cost: formattedCost || "",
                serialNumber: assetDetails.SerialNumber_VC || "",
                invoiceNumber: assetDetails.InvoiceNumber_VC || "",
                PONumber: assetDetails.PONumber_VC || "",
                division: assetDetails.Division_ID || "",
                purchaseStatus: purchaseApproved || "",
                purpose: assetDetails.Purpose_VC || "",
                purchasedBy: assetDetails.PBPeople_ID || "",
                purchasedByName: pbName || "",
                approvedBy: assetDetails.ABPeople_ID || "",
                approvedByName: abName || "",
                photo: assetPhoto || "",
                useStatus: useStatus || "",
                assignedTo: assetDetails.ATPPeople_ID || "",
                assetType: assetDetails.AssetType_ID || "",
                location: "",
                assetNumber: "",
                checkOutDate: "",
                newAssignment: {
                    assigneeName: "",
                    assigneeID: "",
                    assigneeEmail: "",
                    managerEmail: "",
                    assigneeDivisionID: "",
                    assigneeDivisionName: "",
                    checkOutDate: "",
                    checkInDate: "",
                    checkOutCondition: "",
                    checkInCondition: "",
                    checkOutNotes: "",
                    checkOutConditionString: "",
                    checkInConditionString: "",
                    checkInNotes: "",
                    assignmentDirection: ""
                }
            })
        }
        // If pending changes, and not creating a new asset, prompt to save changes to current asset
        else if (pendingChanges) {
            // If editing, don't want to allow changing card - will show a modal asking to save
            handleShow()
        }
        handleShow()
        setLoadingCard(false)
    }
    // Set New Asset mode
    const handleSetNewAssetMode = (wantToCreate) => {
        // Only want to create new if not currently creating another
        if (!creatingNewAsset && wantToCreate && !pendingChanges) {
            setSelCardIndex('')
            // Clear any old information from other assets
            setSelectedAssetInformation({
                assetID: "",
                assetImageID: "",
                purchaseDate: "",
                vendor: "",
                assetDescription: "",
                cost: "",
                serialNumber: "",
                invoiceNumber: "",
                PONumber: "",
                division: "",
                purchaseStatus: "",
                purpose: "",
                purchasedBy: "",
                purchasedByName: "",
                approvedBy: "",
                approvedByName: "",
                photo: "",
                assignmentLog: "",
                useStatus: "1",
                assignedTo: "",
                assetType: "",
                location: "",
                assetNumber: "",
                checkOutDate: "",
                newAssignment: {
                    assigneeName: "",
                    assigneeID: "",
                    assigneeEmail: "",
                    managerEmail: "",
                    assigneeDivisionID: "",
                    assigneeDivisionName: "",
                    checkOutDate: "",
                    checkInDate: "",
                    checkOutCondition: "",
                    checkInCondition: "",
                    checkOutConditionString: "",
                    checkInConditionString: "",
                    checkOutNotes: "",
                    checkInNotes: "",
                    assignmentDirection: ""
                }
            })
            setSelectedAssetInfoUnedited({
                assetID: "",
                assetImageID: "",
                purchaseDate: "",
                vendor: "",
                assetDescription: "",
                cost: "",
                serialNumber: "",
                invoiceNumber: "",
                PONumber: "",
                division: "",
                purchaseStatus: "",
                purpose: "",
                purchasedBy: "",
                purchasedByName: "",
                approvedBy: "",
                approvedByName: "",
                photo: "",
                assignmentLog: "",
                useStatus: "",
                assignedTo: "",
                assetType: "",
                location: "",
                assetNumber: "",
                checkOutDate: "",
                newAssignment: {
                    assigneeName: "",
                    assigneeID: "",
                    assigneeEmail: "",
                    managerEmail: "",
                    assigneeDivisionID: "",
                    assigneeDivisionName: "",
                    checkOutDate: "",
                    checkInDate: "",
                    checkOutCondition: "",
                    checkInCondition: "",
                    checkOutConditionString: "",
                    checkInConditionString: "",
                    checkOutNotes: "",
                    checkInNotes: "",
                    assignmentDirection: ""
                }
            })
            // Turn on creation mode
            setCreatingNewAsset(true)
            // When creating is true, will set unedited to empty strings (default) will use when clearing
        } else if (!wantToCreate) {
            setCreatingNewAsset(false)
        } else if (pendingChanges && wantToCreate) {
            console.log('here')
            handleShow()
        }
        console.log(pendingChanges, wantToCreate)
    }
    // Handle filter radio buttons 
    const handleRadioFilters = (e) => {
        const value = e.target.value;
        switch (e.target.id) {
            case "filter-purchase-status":
                setFilters(prevState => {
                    return { ...prevState, purchaseStatus: value }
                })
                break;
            case "filter-active-status":
                setFilters(prevState => {
                    return { ...prevState, activeStatus: value }
                })
                break;
            default:
                break;
        }
    }
    // Handle selAsset radio buttons 
    const handleRadioSelAsset = (e) => {
        setPendingChanges(true);
        const value = e.target.value;
        switch (e.target.id) {
            case "useStatus":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, useStatus: value }
                })
                break;
            case "purchaseStatus":
                setSelectedAssetInformation(prevState => {
                    return { ...prevState, purchaseStatus: value }
                })
                break;
            default:
                break;
        }
    }


    // Helper function for handleSearchKeyUp
    function getNestedStrings(obj, level) {
        if (obj) {
            if (level === 1) { s = [] }  // at parent level, start with an empty array
            const valArr = Object.values(obj);  // get array of object property values
            const strArr = valArr.filter((o) => { return (typeof o === 'string') });  // filter array for strings
            const objArr = valArr.filter((o) => { return (typeof o === 'object') });  // filter array for objects
            if (strArr.length > 0) { s = [...s, ...strArr]; };  // append strings to result array
            objArr.forEach((x) => { getNestedStrings(x, level + 1) });  // recurse through objects collecting child strings
            if (level === 1) { return s; };  // at parent level, return the string array
        }
    };
    // Handles search bar
    const handleSearchKeyUp = (event) => {
        event.preventDefault();
        const searchString = event.target.value.toLowerCase();
        if (searchString.length && !filters.division.length && !filters.assignment.length && !filters.purchaseStatus.length && !filters.activeStatus.length && !filters.assetType.length) {
            const res = (assetList.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(searchString.toLowerCase()))
                }))
            }));
            setFilteredAssets(res)  // show result object(s)
        }
        else if (searchString.length && (filters.division.length || filters.assignment.length || filters.purchaseStatus.length || filters.activeStatus.length || filters.assetType.length)) {
            const res = (filterCombine.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(searchString.toLowerCase()))
                }))
            }));
            setFilteredAssets(res)  // show result object(s)
        }
        else if (!searchString.length && (filters.division.length || filters.assignment.length || filters.purchaseStatus.length || filters.activeStatus.length || filters.assetType.length)) {
            setFilteredAssets(filterCombine)
        }
        else {
            setFilteredAssets(assetList)
        }
    }

    const handleReapplySearchString = (sort = []) => {
        let assets = []
        if (sort.length > 0) {
            assets = sort
        } else {
            assets = filteredAssets
        }
        if (filters.searchString.length) {
            const res = (assets.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(filters.searchString.toLowerCase()))
                }))
            }));
            setFilteredAssets(res)  // show result object(s)
        }
    }

    // Handle filters
    const handleApplyFilters = async () => {
        setLoadingAssets(true)
        getAssets()

        setSelCardIndex("")
        setSelectedAssetInformation({
            assetID: "",
            assetImageID: "",
            purchaseDate: "",
            vendor: "",
            assetDescription: "",
            cost: "",
            serialNumber: "",
            invoiceNumber: "",
            PONumber: "",
            division: "",
            purchaseStatus: "",
            purpose: "",
            purchasedBy: "",
            purchasedByName: "",
            approvedBy: "",
            approvedByName: "",
            photo: "",
            assignmentLog: "",
            useStatus: "",
            assignedTo: "",
            assetType: "",
            location: "",
            assetNumber: "",
            checkOutDate: "",
            newAssignment: {
                assigneeName: "",
                assigneeID: "",
                assigneeEmail: "",
                managerEmail: "",
                assigneeDivisionID: "",
                assigneeDivisionName: "",
                checkOutDate: "",
                checkInDate: "",
                checkOutCondition: "",
                checkInCondition: "",
                checkOutConditionString: "",
                checkInConditionString: "",
                checkOutNotes: "",
                checkInNotes: "",
                assignmentDirection: ""
            }
        });
        setSelectedAssetInfoUnedited({
            assetID: "",
            assetImageID: "",
            purchaseDate: "",
            vendor: "",
            assetDescription: "",
            cost: "",
            serialNumber: "",
            invoiceNumber: "",
            PONumber: "",
            division: "",
            purchaseStatus: "",
            purpose: "",
            purchasedBy: "",
            purchasedByName: "",
            approvedBy: "",
            approvedByName: "",
            photo: "",
            assignmentLog: "",
            useStatus: "",
            assignedTo: "",
            assetType: "",
            location: "",
            assetNumber: "",
            checkOutDate: "",
            newAssignment: {
                assigneeName: "",
                assigneeID: "",
                assigneeEmail: "",
                managerEmail: "",
                assigneeDivisionID: "",
                assigneeDivisionName: "",
                checkOutDate: "",
                checkInDate: "",
                checkOutCondition: "",
                checkInCondition: "",
                checkOutConditionString: "",
                checkInConditionString: "",
                checkOutNotes: "",
                checkInNotes: "",
                assignmentDirection: ""
            }
        })

        setLoadingAssets(false)
    }


    // Handle cancelling update of assignment for asset and adding to log
    const handleCancelAssignment = () => {
        console.log("cancelAssignment")
        setSelectedAssetInformation(prevState => {
            return {
                ...prevState,
                newAssignment: {
                    assigneeName: "",
                    assigneeID: "",
                    assigneeEmail: "",
                    managerEmail: "",
                    assigneeDivisionID: "",
                    assigneeDivisionName: "",
                    checkOutDate: "",
                    checkInDate: "",
                    checkOutCondition: "",
                    checkInCondition: "",
                    checkOutConditionString: "",
                    checkInConditionString: "",
                    checkOutNotes: "",
                    checkInNotes: "",
                    assignmentDirection: ""

                }
            }
        })
    }
    // Handle saving updates to assignment for asset and adding to log
    const handleSaveAssignment = async (check) => {
        console.log("saveAssignment")
        let oldLog = ''
        let cr = ''
        let add = ''
        let checkDate = ''
        let checkCondition = ''
        let newAssignee = ''
        let notes = ''

        if (selectedAssetInformation.assignmentLog) {
            oldLog = selectedAssetInformation.assignmentLog
            cr = '\r'
        }
        if (check === "Out") {

            if (selectedAssetInformation.newAssignment.checkOutDate) {
                checkDate = selectedAssetInformation.newAssignment.checkOutDate
            }
            if (selectedAssetInformation.newAssignment.checkOutCondition) {
                checkCondition = selectedAssetInformation.newAssignment.checkOutConditionString
            }
            if (selectedAssetInformation.newAssignment.assigneeID) {
                newAssignee = selectedAssetInformation.newAssignment.assigneeName
            }
            if (selectedAssetInformation.newAssignment.checkOutNotes) {
                notes = selectedAssetInformation.newAssignment.checkOutNotes
            }
        } else if (check === "In") {

            if (selectedAssetInformation.newAssignment.checkInDate) {
                checkDate = selectedAssetInformation.newAssignment.checkInDate
            }
            if (selectedAssetInformation.newAssignment.checkInCondition) {
                checkCondition = selectedAssetInformation.newAssignment.checkInConditionString
            }
            if (selectedAssetInformation.newAssignment.assigneeID) {
                newAssignee = selectedAssetInformation.newAssignment.assigneeName
            }
            if (selectedAssetInformation.newAssignment.checkInNotes) {
                notes = selectedAssetInformation.newAssignment.checkInNotes
            }

        }
        add = `${checkDate} ${check}: ${newAssignee} ${'\u00B7'} Condition: ${checkCondition} ${'\u00B7'} ${notes} ${cr}`

        let AssigneeEmail = ''
        let ManagerEmail = ''
        let AssigneeDivisionID = ''
        let AssigneeDivisionVC = ''
        console.log(!checkInLocationList.find((x) => x.Location_VC === selectedAssetInformation.newAssignment.assigneeName), !checkOutLocationList.find((x) => x.Location_VC === selectedAssetInformation.newAssignment.assigneeName))
        if (!checkInLocationList.find((x) => x.Location_VC === selectedAssetInformation.newAssignment.assigneeName) && !checkOutLocationList.find((x) => x.Location_VC === selectedAssetInformation.newAssignment.assigneeName)) {
            let assigneeInfoQuery = `
            {
                getAssigneeManagerInfo(
                    People_ID: "${selectedAssetInformation.newAssignment.assigneeID}"
                ) {
                    AssigneeEmail
                    ManagerEmail
                    AssigneeDivision_ID
                    AssigneeDivision_VC
                }
            }
            `;

            console.log(assigneeInfoQuery)
            const assigneeInfoFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: assigneeInfoQuery }), headers: { 'Content-Type': 'application/json' } });
            const assigneeInfoJson = await assigneeInfoFetch.json();
            const assigneeInfo = assigneeInfoJson.data.getAssigneeManagerInfo[0]
            AssigneeEmail = assigneeInfo.AssigneeEmail
            ManagerEmail = assigneeInfo.ManagerEmail
            AssigneeDivisionID = assigneeInfo.AssigneeDivision_ID
            AssigneeDivisionVC = assigneeInfo.AssigneeDivision_VC
            console.log('assigneeInfoQueryResponse: ', assigneeInfo);
        }

        setSelectedAssetInformation(prevState => {
            return {
                ...prevState,
                assignmentLog: add + oldLog,
                newAssignment: {
                    ...prevState.newAssignment,
                    assignmentDirection: check,
                    assigneeEmail: AssigneeEmail,
                    managerEmail: ManagerEmail,
                    assigneeDivisionID: AssigneeDivisionID,
                    assigneeDivisionName: AssigneeDivisionVC
                }
            }
        })
    }

    async function handleSave() {
        setLoadingSave(true)
        if (pendingChanges && creatingNewAsset) {
            // Handle Save New Employee
            console.log("Save New Asset")

            let location = ""
            let assetNumber = ""
            let costNU = selectedAssetInformation.cost
            if (selectedAssetInformation.cost.includes("$")) {
                costNU = costNU.replace('$', '')
            }
            if (selectedAssetInformation.cost.includes(",")) {
                costNU = costNU.replace(',', '')
            }

            console.log(costNU)
            // TODO: Asset Number & Location & PurchasedBy & ApprovedBy
            let newAssetQuery = `
            {
                addAsset(
                    Description_VC: "${selectedAssetInformation.assetDescription}", 
                    PurchaseDate_DT: "${selectedAssetInformation.purchaseDate}", 
                    Vendor_ID: "${selectedAssetInformation.vendor}", 
                    AssetType_ID: "${selectedAssetInformation.assetType}",
                    Cost_NU: "${costNU}", 
                    SerialNumber_VC: "${selectedAssetInformation.serialNumber}", 
                    InvoiceNumber_VC: "${selectedAssetInformation.invoiceNumber}", 
                    PONumber_VC: "${selectedAssetInformation.PONumber}", 
                    Division_ID: "${selectedAssetInformation.division}", 
                    Purpose_VC: "${selectedAssetInformation.purpose}",
                    PurchaseApproved_BT: "${selectedAssetInformation.purchaseStatus}", 
                    PurchasedBy_ID: "${selectedAssetInformation.purchasedBy}", 
                    ApprovedBy_ID: "${selectedAssetInformation.approvedBy}", 
                    Active_BT: "${selectedAssetInformation.useStatus}", 
                    AssetNumber_IN: "${assetNumber}",
                    Location_ID: "${location}",
                    Image_B64: "${selectedAssetInformation.photo}"
                
                )
            }
            `;

            console.log(newAssetQuery)
            const newAssetFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: newAssetQuery }), headers: { 'Content-Type': 'application/json' } });
            const newAssetJson = await newAssetFetch.json();
            const newAssetID = newAssetJson.data.addAsset
            console.log('newAssetQueryResponse: ', newAssetJson.data.addAsset);

            if (selectedAssetInformation.newAssignment.assigneeID) {
                let assigned = ''
                let conditionID = ''
                let conditionString = ''
                let checkDate = ''
                let notes = ''
                let check = selectedAssetInformation.newAssignment.assignmentDirection
                let assignedToLocation = false
                if (check === "Out") {
                    assigned = 1
                    conditionID = selectedAssetInformation.newAssignment.checkOutCondition
                    conditionString = selectedAssetInformation.newAssignment.checkOutConditionString
                    checkDate = selectedAssetInformation.newAssignment.checkOutDate
                    const notesSingleQuote = selectedAssetInformation.newAssignment.checkOutNotes
                    notes = notesSingleQuote.replace("'", "''")
                } else {
                    assigned = 0
                    conditionID = selectedAssetInformation.newAssignment.checkInCondition
                    conditionString = selectedAssetInformation.newAssignment.checkInConditionString
                    checkDate = selectedAssetInformation.newAssignment.checkInDate
                    const notesSingleQuote = selectedAssetInformation.newAssignment.checkInNotes
                    notes = notesSingleQuote.replace("'", "''")
                }

                // Assigned asset, insert asset assignment entry
                let newAssignmentQuery = `
                {
                    addAssetAssignment(
                     Asset_ID: "${newAssetID}"
                     Condition_ID: "${conditionID}"
                     CheckInOut_DT: "${checkDate}"
                     Assigned_BT: "${assigned}"
                     Current_BT: "1"
                     Notes_VC: "${notes}"
                `

                if (checkInLocationList.find((x) => x.Location_VC === selectedAssetInformation.newAssignment.assigneeName) || checkOutLocationList.find((x) => x.Location_VC === selectedAssetInformation.newAssignment.assigneeName)) {
                    // assignee is a location
                    newAssignmentQuery += `AssignedToLocation_ID: "${selectedAssetInformation.newAssignment.assigneeID}"`
                    assignedToLocation = true

                } else {
                    newAssignmentQuery += `AssignedToPerson_ID: "${selectedAssetInformation.newAssignment.assigneeID}"`
                }

                newAssignmentQuery += `
                   )
                }
                `;

                const newAssignmentFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: newAssignmentQuery }), headers: { 'Content-Type': 'application/json' } });
                const newAssignmentJson = await newAssignmentFetch.json();
                console.log('assignmentQueryResponse: ', newAssignmentJson);

                if (check === "Out") {

                    let purchaseDate = selectedAssetInformation.purchaseDate
                    if (selectedAssetInformation.purchaseDate && selectedAssetInformation.purchaseDate !== "" && selectedAssetInformation.purchaseDate.includes("T")) {
                        const purchaseDateArr = selectedAssetInformation.purchaseDate.split("T")
                        purchaseDate = purchaseDateArr[0]
                    }
                    console.log("purchaseDate", purchaseDate)

                    if (!assignedToLocation) {
                        let emailQuery = `
                        {
                            emailAssignmentConfirmation (
                                Description_VC: ${JSON.stringify(selectedAssetInformation.assetDescription)}
                                SerialNumber_VC: ${JSON.stringify(selectedAssetInformation.serialNumber)}
                                PurchaseDate_DT: ${JSON.stringify(purchaseDate)}
                                PBName_VC: ${JSON.stringify(selectedAssetInformation.purchasedByName)}
                                ABName_VC: ${JSON.stringify(selectedAssetInformation.approvedByName)}
                                Purpose_VC: ${JSON.stringify(selectedAssetInformation.purpose)}
                                ATPName_VC: ${JSON.stringify(selectedAssetInformation.newAssignment.assigneeName)}
                                Division_ID: ${JSON.stringify(selectedAssetInformation.newAssignment.assigneeDivisionID)}
                                Division_VC: ${JSON.stringify(selectedAssetInformation.newAssignment.assigneeDivisionName)}
                                CheckDate_DT: ${JSON.stringify(checkDate)}
                                Condition_VC: ${JSON.stringify(conditionString)}
                                Notes_VC: ${JSON.stringify(notes)}
                                AssigneeEmail: "${selectedAssetInformation.newAssignment.assigneeEmail}"
                                ManagerEmail:"${selectedAssetInformation.newAssignment.managerEmail}"
                            )
                        }
                    `;
                        // AssigneeEmail: "${selectedAssetInformation.newAssignment.assigneeEmail}"
                        //         ManagerEmail:"${selectedAssetInformation.newAssignment.managerEmail}"
                        console.log(emailQuery)
                        const emailFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: emailQuery }), headers: { 'Content-Type': 'application/json' } });
                        const emailJson = await emailFetch.json();
                        console.log('emailQueryResponse: ', emailJson);
                    }
                }
            }

            handleSetNewAssetMode(false)
            setPendingChanges(false)

            getAssets()

        } else if (pendingChanges && !creatingNewAsset) {
            // Handle Save Edits
            console.log("Save Edits")

            let costNU = selectedAssetInformation.cost
            if (selectedAssetInformation.cost.includes("$")) {
                costNU = costNU.replace('$', '')
            }
            if (selectedAssetInformation.cost.includes(",")) {
                costNU = costNU.replace(',', '')
            }
            console.log(costNU)
            // TODO: Asset Number & Location & PurchasedBy & ApprovedBy
            let updateAssetQuery = `
                {
                    updateAsset(
                        Asset_ID: "${selectedAssetInformation.assetID}"
                        AssetNumber_IN: "${selectedAssetInformation.assetNumber}"
                        Description_VC: "${selectedAssetInformation.assetDescription}"
                        PurchaseDate_DT: "${selectedAssetInformation.purchaseDate}"
                        Vendor_ID: "${selectedAssetInformation.vendor}"
                        AssetType_ID: "${selectedAssetInformation.assetType}"
                        Cost_NU: "${costNU}"
                        SerialNumber_VC: "${selectedAssetInformation.serialNumber}"
                        InvoiceNumber_VC: "${selectedAssetInformation.invoiceNumber}"
                        PONumber_VC: "${selectedAssetInformation.PONumber}"
                        Division_ID: "${selectedAssetInformation.division}"
                        PurchaseApproved_BT: "${selectedAssetInformation.purchaseStatus}"
                        Purpose_VC: "${selectedAssetInformation.purpose}"
                        PurchasedBy_ID: "${selectedAssetInformation.purchasedBy}"
                        ApprovedBy_ID: "${selectedAssetInformation.approvedBy}"
                        Active_BT: "${selectedAssetInformation.useStatus}"
                        Location_ID: "${selectedAssetInformation.location}" 
                    )
                }
            `;
            console.log(updateAssetQuery)
            const updateAssetFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: updateAssetQuery }), headers: { 'Content-Type': 'application/json' } });
            const updateAssetJson = await updateAssetFetch.json();
            console.log('assetInfoQueryResponse: ', updateAssetJson);

            if (selectedAssetInformation.newAssignment.assigneeID) {
                let assigned = ''
                let conditionID = ''
                let conditionString = ''
                let checkDate = ''
                let notes = ''
                let check = selectedAssetInformation.newAssignment.assignmentDirection
                let assignedToLocation = false
                if (check === "Out") {
                    assigned = 1
                    conditionID = selectedAssetInformation.newAssignment.checkOutCondition
                    conditionString = selectedAssetInformation.newAssignment.checkOutConditionString
                    checkDate = selectedAssetInformation.newAssignment.checkOutDate
                    const notesSingleQuote = selectedAssetInformation.newAssignment.checkOutNotes
                    notes = notesSingleQuote.replace("'", "''")
                } else {
                    assigned = 0
                    conditionID = selectedAssetInformation.newAssignment.checkInCondition
                    conditionString = selectedAssetInformation.newAssignment.checkInConditionString
                    checkDate = selectedAssetInformation.newAssignment.checkInDate
                    const notesSingleQuote = selectedAssetInformation.newAssignment.checkInNotes
                    notes = notesSingleQuote.replace("'", "''")
                }

                // Assigned asset, insert asset assignment entry
                let newAssignmentQuery = `
                {
                    addAssetAssignment(
                     Asset_ID: "${selectedAssetInformation.assetID}"
                     Condition_ID: "${conditionID}"
                     CheckInOut_DT: "${checkDate}"
                     Assigned_BT: "${assigned}"
                     Current_BT: "1"
                     Notes_VC: "${notes}"
                `

                if (checkInLocationList.find((x) => x.Location_VC === selectedAssetInformation.newAssignment.assigneeName) || checkOutLocationList.find((x) => x.Location_VC === selectedAssetInformation.newAssignment.assigneeName)) {
                    // assignee is a location
                    assignedToLocation = true
                    newAssignmentQuery += `AssignedToLocation_ID: "${selectedAssetInformation.newAssignment.assigneeID}"`
                } else {
                    newAssignmentQuery += `AssignedToPerson_ID: "${selectedAssetInformation.newAssignment.assigneeID}"`
                }

                newAssignmentQuery += `
                   )
                }
                `;

                const newAssignmentFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: newAssignmentQuery }), headers: { 'Content-Type': 'application/json' } });
                const newAssignmentJson = await newAssignmentFetch.json();
                console.log('assignmentQueryResponse: ', newAssignmentJson);

                if (check === "Out") {

                    let purchaseDate = selectedAssetInformation.purchaseDate
                    if (selectedAssetInformation.purchaseDate && selectedAssetInformation.purchaseDate !== "" && selectedAssetInformation.purchaseDate.includes("T")) {
                        const purchaseDateArr = selectedAssetInformation.purchaseDate.split("T")
                        purchaseDate = purchaseDateArr[0]
                    }

                    if (!assignedToLocation) {
                        let emailQuery = `
                        {
                            emailAssignmentConfirmation (
                                Description_VC: "${selectedAssetInformation.assetDescription}"
                                SerialNumber_VC: "${selectedAssetInformation.serialNumber}"
                                PurchaseDate_DT: "${purchaseDate}"
                                PBName_VC: "${selectedAssetInformation.purchasedByName}"
                                ABName_VC: "${selectedAssetInformation.approvedByName}"
                                Purpose_VC: "${selectedAssetInformation.purpose}"
                                ATPName_VC: "${selectedAssetInformation.newAssignment.assigneeName}"
                                Division_ID: "${selectedAssetInformation.newAssignment.assigneeDivisionID}"
                                Division_VC: "${selectedAssetInformation.newAssignment.assigneeDivisionName}"
                                CheckDate_DT: "${checkDate}"
                                Condition_VC: "${conditionString}"
                                Notes_VC: "${notes}"
                                AssigneeEmail: "${selectedAssetInformation.newAssignment.assigneeEmail}"
                                ManagerEmail:"${selectedAssetInformation.newAssignment.managerEmail}"
                            )
                        }
                    `;
                        // AssigneeEmail: "${selectedAssetInformation.newAssignment.assigneeEmail}"
                        //         ManagerEmail:"${selectedAssetInformation.newAssignment.managerEmail}"
                        console.log(emailQuery)
                        const emailFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: emailQuery }), headers: { 'Content-Type': 'application/json' } });
                        const emailJson = await emailFetch.json();
                        console.log('emailQueryResponse: ', emailJson);
                    }
                }
            }

            if (newImageB64 && selectedAssetInformation.assetImageID !== "") {
                // If we have new image, and assetImageID is not empty, we have an existing image entry, need to update
                //`Image_B64: "${selectedAssetInformation.photo}"`
                let updateImageQuery = `
                    {
                        updateAssetImage(
                            AssetImage_ID: "${selectedAssetInformation.assetImageID}"
                            Image_B64: "${selectedAssetInformation.photo}"
                            Vendor_ID: "${selectedAssetInformation.vendor}"
                            Asset_ID: "${selectedAssetInformation.assetID}"
                        )
                    }
                `;
                // console.log(updateImageQuery)
                const updateImageFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: updateImageQuery }), headers: { 'Content-Type': 'application/json' } });
                const updateImageJson = await updateImageFetch.json();
                console.log('updateImageQueryResponse: ', updateImageJson);
            } else if (newImageB64 && selectedAssetInformation.assetImageID === "") {
                // If we have a new image and we don't have an existing assetImageID, no existing image for asset, add entry
                let addImageQuery = `
                {
                    addAssetImage(
                        Image_B64: "${selectedAssetInformation.photo}"
                        Vendor_ID: "${selectedAssetInformation.vendor}"
                        Asset_ID: "${selectedAssetInformation.assetID}"
                        Active_BT: "1"
                        Primary_BT: "1"
                    )
                }
            `;
                // console.log(addImageQuery)
                const addImageFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: addImageQuery }), headers: { 'Content-Type': 'application/json' } });
                const addImageJson = await addImageFetch.json();
                const imageID = addImageJson.data.addAssetImage
                console.log('addImageQueryResponse: ', addImageJson);
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        assetImageID: imageID
                    }
                })
            }

            if (removePhoto) {
                console.log('removing photo')
                let deactivateImageQuery = `
                {
                updateAssetImage(
                        AssetImage_ID: "${selectedAssetInformation.assetImageID}"
                        Active_BT: "0"
                    )
                }
                `;
                // console.log(deactivateImageQuery)
                const deactivateImageFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: deactivateImageQuery }), headers: { 'Content-Type': 'application/json' } });
                const deactivateImageJson = await deactivateImageFetch.json();
                console.log('deactivateImageQueryResponse: ', deactivateImageJson);
                setSelectedAssetInformation(prevState => {
                    return {
                        ...prevState,
                        assetImageID: ''
                    }
                })
            }

            setPendingChanges(false)

            getAssets()
        } else {
            // No pending changes. Don't save anything
        }
        setSelectedAssetInformation(prevState => {
            return {
                ...prevState,
                newAssignment: {
                    assigneeName: "",
                    assigneeID: "",
                    assigneeEmail: "",
                    managerEmail: "",
                    assigneeDivisionID: "",
                    assigneeDivisionName: "",
                    checkOutDate: "",
                    checkInDate: "",
                    checkOutCondition: "",
                    checkInCondition: "",
                    checkOutConditionString: "",
                    checkInConditionString: "",
                    checkOutNotes: "",
                    checkInNotes: "",
                    assignmentDirection: ""

                }
            }
        })
        setNewImageB64(false)
        handleClose()
        setLoadingSave(false)
        setRemovePhoto(false)
    }

    const handleCancel = () => {
        if (pendingChanges && creatingNewAsset) {
            console.log("Cancel New Asset")
            // Handle Cancel  New Employee
            setSelectedAssetInformation(selectedAssetInfoUnedited)
            handleSetNewAssetMode(false)
            setPendingChanges(false);
        } else if (pendingChanges && !creatingNewAsset) {
            // Handle Cancel Edits
            console.log("Cancel Edits")
            setSelectedAssetInformation(selectedAssetInfoUnedited)
            setPendingChanges(false)
        } else if (!pendingChanges && creatingNewAsset) {
            // Started creating new employee, but didn't add any information
            setSelectedAssetInformation(selectedAssetInfoUnedited)
            handleSetNewAssetMode(false)
        } else {
            // No pending changes.
        }
        setRemovePhoto(false)
        setNewImageB64(false)
        handleClose()
    }

    function formatDateDisplay(date) {
        if (date) {
            let split = date.split("T")
            return split[0]
        }
    }

    // Function to sort data 
    function sortAssets() {
        const sortedFirst = filteredAssets.sort((a, b) => {
            if (a.ATPFirstName_VC < b.ATPFirstName_VC) {
                return -1;
            } else if (a.ATPFirstName_VC > b.ATPFirstName_VC) {
                return 1;
            } else {
                return 0
            }
        });
        const sort = sortedFirst.sort((a, b) => {
            if (a.ATPLastName_VC < b.ATPLastName_VC) {
                return -1;
            } else if (a.ATPLastName_VC > b.ATPLastName_VC) {
                return 1;
            } else {
                return 0
            }
        })
        setFilteredAssets(sort)
    }

    // handle preview pdf
    const handlePreviewReport = async () => {
        setLoadingPdfPreview(true)
        let endpoint = '';
        let data = '';
        switch (selReportType) {
            // Endpoint not yet ready
            case "Purchase Report":
                endpoint = '/assetPurchaseReport'
                let purchaseReportQuery = `
                {
                    getAssetInfoForPurchaseReport(
                           Asset_ID: "${selectedAssetInformation.assetID}"
                        ){
                            Asset_ID
                            Description_VC
                            PurchaseDate_DT
                            Vendor_VC
                            AssetType_VC
                            Cost_NU
                            SerialNumber_VC
                            InvoiceNumber_VC
                            PONumber_VC
                            Division_ID
                            Division_VC
                            PurchaseApproved_BT
                            Purpose_VC
                            Active_BT
                            PBFirstName_VC
                            PBLastName_VC
                            ABFirstName_VC
                            ABLastName_VC
                            ATPFirstName_VC
                            ATPLastName_VC
                            ALocation_VC
                            ADivision_VC
                            ADivision_ID
                            CheckInOut_DT
                            Condition_ID
                            Condition_VC
                            ImagePath_VC 
                            AIActive_BT
                        }
                    }
                `;
                console.log(purchaseReportQuery)
                const purchaseReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: purchaseReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const purchaseReportJson = await purchaseReportFetch.json();
                console.log('purchaseReportQueryResponse: ', purchaseReportJson);

                data = JSON.stringify(purchaseReportJson.data.getAssetInfoForPurchaseReport)
                break;
            case "Filtered Asset List":
                endpoint = '/assetInventoryList'
                // #region 
                let assetIDList = []
                filteredAssets.forEach((asset) => {
                    assetIDList.push(asset.Asset_ID)
                })
                let filteredAssetReportQuery = `
                {
                getAssetsForFilteredAssetReport(
                    AssetIDList: ${JSON.stringify(assetIDList)}
                    ) {
                        Asset_ID
                        Description_VC
                        SerialNumber_VC
                        ATPFirstName_VC
                        ATPLastName_VC
                        ALocation_VC
                    }
                }
                `;
                console.log(filteredAssetReportQuery)
                const filteredAssetReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: filteredAssetReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const filteredAssetReportJson = await filteredAssetReportFetch.json();
                console.log('filteredAssetReportQueryResponse: ', filteredAssetReportJson.data.getAssetsForFilteredAssetReport);
                data = JSON.stringify({ "assets": filteredAssetReportJson.data.getAssetsForFilteredAssetReport })
                break;
            default:
                break;
        }
        const reportURL = reportServiceURL + endpoint;

        await fetch(reportURL, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: data
        }).then(response => {
            setLoadingPdfPreview(false);
            response.blob().then(blob => {
                // Creating new object of PDF file
                setPdf(window.URL.createObjectURL(blob))
            })
        })
    }

    // Handle getting report
    async function handleDownloadReport() {
        setDownloadingPdf(true);
        let endpoint = '';
        let data = '';
        switch (selReportType) {
            // Endpoint not yet ready
            case "Purchase Report":
                endpoint = '/assetPurchaseReport'
                let purchaseReportQuery = `
                {
                    getAssetInfoForPurchaseReport(
                           Asset_ID: "${selectedAssetInformation.assetID}"
                        ){
                            Asset_ID
                            Description_VC
                            PurchaseDate_DT
                            Vendor_VC
                            AssetType_VC
                            Cost_NU
                            SerialNumber_VC
                            InvoiceNumber_VC
                            PONumber_VC
                            Division_ID
                            Division_VC
                            PurchaseApproved_BT
                            Purpose_VC
                            Active_BT
                            PBFirstName_VC
                            PBLastName_VC
                            ABFirstName_VC
                            ABLastName_VC
                            ATPFirstName_VC
                            ATPLastName_VC
                            ALocation_VC
                            ADivision_VC
                            ADivision_ID
                            CheckInOut_DT
                            Condition_ID
                            Condition_VC
                            ImagePath_VC 
                            AIActive_BT
                        }
                    }
                `;
                console.log(purchaseReportQuery)
                const purchaseReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: purchaseReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const purchaseReportJson = await purchaseReportFetch.json();
                console.log('purchaseReportQueryResponse: ', purchaseReportJson);

                data = JSON.stringify(purchaseReportJson.data.getAssetInfoForPurchaseReport)
                break;
            case "Filtered Asset List":
                endpoint = '/assetInventoryList'
                // #region 
                let assetIDList = []
                filteredAssets.forEach((asset) => {
                    assetIDList.push(asset.Asset_ID)
                })
                let filteredAssetReportQuery = `
                {
                getAssetsForFilteredAssetReport(
                    AssetIDList: ${JSON.stringify(assetIDList)}
                    ) {
                        Asset_ID
                        Description_VC
                        SerialNumber_VC
                        ATPFirstName_VC
                        ATPLastName_VC
                        ALocation_VC
                    }
                }
                `;
                console.log(filteredAssetReportQuery)
                const filteredAssetReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: filteredAssetReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const filteredAssetReportJson = await filteredAssetReportFetch.json();
                console.log('filteredAssetReportQueryResponse: ', filteredAssetReportJson.data.getAssetsForFilteredAssetReport);
                data = JSON.stringify({ "assets": filteredAssetReportJson.data.getAssetsForFilteredAssetReport })
                break;
            default:
                break;
        }
        const reportURL = reportServiceURL + endpoint;
        await fetch(reportURL, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: data
        }).then(response => {
            response.blob().then(blob => {
                setDownloadingPdf(false);
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${selReportType}.pdf`;
                alink.click();
            })
        })
    }


    const handleTakePhoto = (e) => {
        e.preventDefault()
        console.log('photohandler', e.target.files[0])
        // if (
        //     fileInput.current
        //     && fileInput.current.files
        //     && fileInput.current.files.length > 0
        // ) 
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            console.log(reader)
            reader.onload = () => {
                setNewImageB64(true);
                setSelectedAssetInformation(prevState => {
                    console.log('image', reader.result)
                    return { ...prevState, photo: reader.result }
                });
            };
            setPendingChanges(true)
        }
    };

    const handleRemovePhoto = () => {
        console.log('removePhotoHandler', removePhoto)
        setSelectedAssetInformation(prevState => {
            return {
                ...prevState,
                photo: ""
            }
        });
        setRemovePhoto(true)
        setPendingChanges(true)
    };

    // uses getAssetsForCardListFiltered query - if no filters or '' sent for each filter in query, returns all assets
    async function getAssets() {
        setLoadingAssets(true)
        const filterForDivision = filters.division;
        const filterForAssignment = filters.assignment;
        const filterForAssignmentString = filters.assignmentString
        const filterForPurchaseStatus = filters.purchaseStatus;
        const filterForActiveStatus = filters.activeStatus;
        const filterForAssetType = filters.assetType;

        let filterQuery = `
            {
                getAssetsForCardListFiltered(
                    Division_ID: "${filterForDivision}"
                    PurchaseApproved_BT: "${filterForPurchaseStatus}"
                    Active_BT: "${filterForActiveStatus}"
                    AssetType_ID: "${filterForAssetType}"
        `
        if (checkInLocationList.find((x) => x.Location_VC === filterForAssignmentString) || checkOutLocationList.find((x) => x.Location_VC === filterForAssignmentString)) {
            // assignee is a location
            filterQuery += `Location_ID: "${filterForAssignment}"`
        } else {
            filterQuery += `People_ID: "${filterForAssignment}"`
        }

        filterQuery += `
                  ) {
                    Asset_ID
                    Description_VC 
                    PurchaseDate_DT 
                    AssetType_ID
                    AssetType_VC 
                    SerialNumber_VC 
                    PurchaseApproved_BT
                    Active_BT
                    Division_ID 
                    Division_VC 
                    ATPFirstName_VC 
                    ATPLastName_VC 
                    ATPPeople_ID 
                    Location_ID 
                    Location_VC
                    CheckDate_DT
                    Assigned_BT
                    ALocation_ID
                    ALocation_VC
                  }
            }
        `;

        console.log(filterQuery)
        const filterFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: filterQuery }), headers: { 'Content-Type': 'application/json' } });
        const filterJson = await filterFetch.json();
        console.log('filterQueryResponse: ', filterJson.data.getAssetsForCardListFiltered);
        const assets = filterJson.data.getAssetsForCardListFiltered

        assets.forEach((asset) => {
            if (asset.Assigned_BT === "true" || asset.Assigned_BT === true || asset.Assigned_BT === 1) {
                // Only want date (formatted) if the asset is checked out
                asset.CheckDate_DT = formatDateDisplay(asset.CheckDate_DT);
            } else {
                // Else, asset is checked in, no checkout date applicable, set to "" to avoid having a checkin date instead
                asset.CheckDate_DT = ''
            }

            asset.PurchaseDate_DT = formatDateDisplay(asset.PurchaseDate_DT);
            let purchaseApproved = ''
            if (asset.PurchaseApproved_BT !== "" && asset.PurchaseApproved_BT !== null && (asset.PurchaseApproved_BT === "true" || asset.PurchaseApproved_BT === 1)) {
                purchaseApproved = '1'
            } else if (asset.PurchaseApproved_BT !== "" && asset.PurchaseApproved_BT !== null && (asset.PurchaseApproved_BT === "false" || asset.PurchaseApproved_BT === 0)) {
                purchaseApproved = '0'
            } else {
                purchaseApproved = asset.PurchaseApproved_BT
            }

            let useStatus = ''
            if (asset.Active_BT !== "" && asset.Active_BT !== null && (asset.Active_BT === "true" || asset.Active_BT === 1)) {
                useStatus = '1'
            } else if (asset.Active_BT !== "" && asset.Active_BT !== null && (asset.Active_BT === "false" || asset.Active_BT === 0)) {
                useStatus = '0'
            } else {
                useStatus = asset.Active_BT
            }

            asset.PurchaseApproved_BT = purchaseApproved
            asset.Active_BT = useStatus

            let assignedTo = ''
            if (asset.ALocation_VC !== "" && asset.ALocation_VC !== null) {
                assignedTo = asset.ALocation_VC
            } else if (asset.ATPFirstName_VC !== "" && asset.ATPFirstName_VC !== null) {
                assignedTo = `${asset.ATPFirstName_VC} ${asset.ATPLastName_VC}`
            }

            asset.AssignedTo = assignedTo
        })

        const sortedFirst = assets.sort((a, b) => {
            if (a.ATPFirstName_VC < b.ATPFirstName_VC) {
                return -1;
            } else if (a.ATPFirstName_VC > b.ATPFirstName_VC) {
                return 1;
            } else {
                return 0
            }
        });
        const sort = sortedFirst.sort((a, b) => {
            if (a.ATPLastName_VC < b.ATPLastName_VC) {
                return -1;
            } else if (a.ATPLastName_VC > b.ATPLastName_VC) {
                return 1;
            } else {
                return 0
            }
        })
        setFilteredAssets(sort)
        setFilterCombine(sort)
        setAssetList(sort);
        setLoadingAssets(false)
        handleReapplySearchString(sort)

    }

    async function getLists() {
        // Get filtered assets if there are initial filters, otherwise get all assets (setup for additional feature
        // where assets will be filtered upon login)
        getAssets()

        // #region People
        let managers = []
        const getPeopleQuery = `
        {
            getPeopleForPicklist {
                People_ID
                FirstName_VC
                LastName_VC
                IsManager_BT
            }
        }
        `;
        const getPeopleFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getPeopleQuery }), headers: { 'Content-Type': 'application/json' } });
        const getPeopleJson = await getPeopleFetch.json();
        console.log('p', getPeopleJson.data.getPeopleForPicklist);
        const people = getPeopleJson.data.getPeopleForPicklist
        people.forEach((person) => {
            if (person.IsManager_BT === 'true') {
                managers.push(person)
            }
        })
        console.log('managers', managers)
        setManagerList(managers);
        setPeopleList(people);

        // #endregion

        // #region Locations
        const getCheckInLocationsQuery = `
            {
                getLocations(
                    Assignee_BT: "0"
                ) {
                    Location_ID
                    Location_VC
                }
            }
        `;
        const getCheckInLocationsFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getCheckInLocationsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getCheckInLocationsJson = await getCheckInLocationsFetch.json();
        console.log('l', getCheckInLocationsJson.data.getLocations);
        const ciLocations = getCheckInLocationsJson.data.getLocations
        setCheckInLocationList(ciLocations);

        const getCheckOutLocationsQuery = `
        {
            getLocations(
                Assignee_BT: "1"
            ) {
                Location_ID
                Location_VC
            }
        }
    `;
        const getCheckOutLocationsFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getCheckOutLocationsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getCheckOutLocationsJson = await getCheckOutLocationsFetch.json();
        console.log('l', getCheckOutLocationsJson.data.getLocations);
        const coLocations = getCheckOutLocationsJson.data.getLocations
        setCheckOutLocationList(coLocations);
        // #endregion

        let managersAndLocations = []
        let peopleAndLocations = []
        let checkOutList = []

        coLocations.forEach((location) => {
            checkOutList.push(
                {
                    id: location.Location_ID,
                    name: location.Location_VC
                }
            )
            peopleAndLocations.push(
                {
                    id: location.Location_ID,
                    name: location.Location_VC
                }
            )
        });
        ciLocations.forEach((location) => {
            peopleAndLocations.push(
                {
                    id: location.Location_ID,
                    name: location.Location_VC
                }
            )
            managersAndLocations.push(
                {
                    id: location.Location_ID,
                    name: location.Location_VC
                }
            )
        });
        people.forEach((person) => {
            peopleAndLocations.push(
                {
                    id: person.People_ID,
                    name: `${person.LastName_VC}, ${person.FirstName_VC}`
                }
            )
            checkOutList.push(
                {
                    id: person.People_ID,
                    name: `${person.LastName_VC}, ${person.FirstName_VC}`
                }
            )
        })
        managers.forEach((manager) => {
            managersAndLocations.push(
                {
                    id: manager.People_ID,
                    name: `${manager.LastName_VC}, ${manager.FirstName_VC}`
                }
            )
        })

        setCheckInList(managersAndLocations)
        setAllPeopleLocationsList(peopleAndLocations)
        setAssigneeList(checkOutList);
        // TODO Set FilterList


        // #region Divisions
        const getDivisionsQuery = `
        {
            getDivisions {
              Division_ID
              Division_VC
            }
        }
        `;
        const getDivisionsFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getDivisionsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getDivisionsJson = await getDivisionsFetch.json();
        console.log(getDivisionsJson.data.getDivisions);
        setDivisionList(getDivisionsJson.data.getDivisions);
        // #endregion

        // #region Asset Types
        const getAssetTypesQuery = `
        {
            getAssetTypes {
              AssetType_ID
              AssetType_VC
            }
        }
        `;
        const getAssetTypesFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getAssetTypesQuery }), headers: { 'Content-Type': 'application/json' } });
        const getAssetTypesJson = await getAssetTypesFetch.json();
        console.log(getAssetTypesJson.data.getAssetTypes);
        setAssetTypeList(getAssetTypesJson.data.getAssetTypes);
        // #endregion

        // #region Vendors
        const getVendorsQuery = `
        {
            getVendors {
              Vendor_ID
              Vendor_VC
            }
        }
        `;
        const getVendorsFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getVendorsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getVendorsJson = await getVendorsFetch.json();
        console.log('v', getVendorsJson.data.getVendors);
        setVendorList(getVendorsJson.data.getVendors);
        // #endregion

        // #region Conditions
        const getConditionsQuery = `
                {
                    getConditions {
                      Condition_ID
                      Condition_VC
                    }
                }
                `;
        const getConditionsFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getConditionsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getConditionsJson = await getConditionsFetch.json();
        console.log('c', getConditionsJson.data.getConditions);
        setConditionsList(getConditionsJson.data.getConditions);
        // #endregion

    }


    useEffect(() => {
        filteredAssets.forEach((asset) => {
            asset.CheckDate_DT = formatDateDisplay(asset.CheckDate_DT);
            asset.PurchaseDate_DT = formatDateDisplay(asset.PurchaseDate_DT)
        })
        sortAssets()
        // eslint-disable-next-line 
    }, [filteredAssets])

    useEffect(() => {

        if (!pendingChanges) {
            setSelectedAssetInfoUnedited(selectedAssetInformation)
        }
        // eslint-disable-next-line 
    }, [pendingChanges])

    useEffect(() => {

        let assignmentLogText = ''
        if (assetAssignments && assetAssignments.length && assetAssignments.length > 0) {
            assetAssignments.forEach((assignment) => {
                let check = ''
                let checkDateFormat = assignment.CheckInOut_DT
                if (checkDateFormat && checkDateFormat !== "" && checkDateFormat !== null && checkDateFormat.includes("T")) {
                    const splitDate = checkDateFormat.split('T')
                    checkDateFormat = splitDate[0]
                }

                if (assignment.Assigned_BT === true || assignment.Assigned_BT === 1 || assignment.Assigned_BT === "true") {
                    check = 'Out'
                } else {
                    check = 'In'
                }

                let assigneeName = ''
                if (assignment.FirstName_VC !== null && assignment.FirstName_VC !== "") {
                    console.log("ugh")
                    assigneeName = `${assignment.FirstName_VC} ${assignment.LastName_VC}`
                } else if (assignment.Location_VC !== null && assignment.Location_VC !== "") {
                    console.log("HERE")
                    assigneeName = assignment.Location_VC
                }

                let condition = assignment.Condition_VC || "Unknown"
                const notes = assignment.Notes_VC
                let appendNotes = ''
                if (notes && notes !== null && notes !== undefined && notes !== "") {
                    appendNotes = `${'\u00B7'} ${assignment.Notes_VC}`
                }
                let string = `${checkDateFormat} ${check}: ${assigneeName} ${'\u00B7'} Condition: ${condition} ${appendNotes} \r`
                assignmentLogText += string
            })
        }
        console.log("log", assignmentLogText, assetAssignments)

        setSelectedAssetInformation(prevState => {
            return { ...prevState, assignmentLog: assignmentLogText }
        })
    }, [assetAssignments])


    useEffect(() => {
        getLists()
        // eslint-disable-next-line 
    }, []);

    return (
        <>
            <div className="freeze-pane" style={{ "backgroundColor": "#FEFEFE" }}>
                {/* <div className="container-fluid">
                    <nav className="navbar ST-navbar fixed-top">
                        <div className="container-fluid">
                            <div>
                                <a className="navbar-brand" href="https://servitech.com"><img alt='ServiTech Logo redirects to ServiTech website' src={ServitechLogoColor} height="35px" /></a>
                            </div>
                            <div className="service-title">Asset Manager</div>
                            <div className="ps-5 pe-0">
                                <div className="navbar-nav" data-bs-toggle="dropdown">
                                    <i className="bi bi-three-dots" style={{ "fontSize": "1.5rem", "color": "#000000", "cursor": "pointer" }}></i>
                                </div>
                                <ul className="me-0 dropdown-menu dropdown-menu-end">

                                    <li>
                                        <a className="dropdown-item" href="https://intranet.servitech.com">Return to Intranet</a>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li>
                                        <button className="dropdown-item" href="#">Logout</button>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </nav>
                </div> */}

                <div className="container-fluid" >
                    {downloadingPdf &&
                        <div width="100%" className="downloading">
                            <Spinner></Spinner>
                        </div>
                    }
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="d-flex search-bar">
                                <div className="mx-2 ps-0">
                                    {!pendingChanges &&
                                        <div data-bs-toggle="modal" data-bs-target="#filter-modal">
                                            <i className="bi bi-filter-circle" onClick={() => setFiltersUnedited(filters)} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                        </div>
                                    }
                                    {pendingChanges &&
                                        <div>
                                            <i className="bi bi-filter-circle" onClick={() => handleShow()} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                        </div>
                                    }
                                </div>

                                <div className="input-group mb-2">
                                    {!pendingChanges &&
                                        <input type="text" className="form-control ST-form mx-0" id="filter-search-bar" style={{ "textJustify": "right" }} onKeyUp={handleSearchKeyUp} placeholder="Start typing to search..." value={filters.searchString} onChange={(e) => handleOnChangeFiltersReports(e)} />
                                    }
                                    {pendingChanges &&
                                        <input type="text" className="form-control ST-form mx-0" id="filter-search-bar" style={{ "textJustify": "right" }} disabled placeholder={filters.searchString} />
                                    }

                                </div>
                                <div className="mx-2 ps-0">
                                    <div data-bs-toggle="modal" data-bs-target="#mobile-view-modal" className="mobile-card">
                                        <i className="bi bi-plus-square" data-bs-toggle="tooltip" title="Add New Asset" style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }} onClick={() => handleSetNewAssetMode(true)}></i>
                                    </div>
                                    <div className="desktop-card">
                                        <i className="bi bi-plus-square" data-bs-toggle="tooltip" title="Add New Asset" style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }} onClick={() => handleSetNewAssetMode(true)}></i>
                                    </div>
                                </div>
                                {/* <div className="mx-2 ps-0">
                                    <a>
                                        <i className="bi bi-pencil-square" data-bs-toggle="tooltip" title="Edit Asset" style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }} onClick={() => handleSetEditingMode(true)}></i>
                                    </a>
                                </div> */}
                                <div className="mx-2 ps-0">
                                    {!pendingChanges &&
                                        <div data-bs-toggle="modal" data-bs-target="#report-modal">
                                            <i className="bi bi-file-earmark-arrow-up" data-bs-toggle="tooltip" title="Export Reports" style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                        </div>
                                    }
                                    {pendingChanges &&
                                        <div>
                                            <i className="bi bi-file-earmark-arrow-up" data-bs-toggle="tooltip" title="Export Reports" onClick={() => handleShow()} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                        </div>
                                    }
                                </div>

                            </div>



                            <div className="modal fade" id="filter-modal" aria-hidden="true" aria-labelledby="Set Filters" tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5">Set Filters</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="mt-0">
                                                <label htmlFor="filter-division" className="form-label service-form-label">Filter by Division</label>
                                                <select className="form-select" id='filter-division' value={filters.division} onChange={(e) => handleOnChangeFiltersReports(e)}>
                                                    <option value="">All</option>
                                                    {divisionList.map((division) =>
                                                        <option value={division.Division_ID} key={division.Division_ID}>{division.Division_ID} - {division.Division_VC}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <hr className="mt-4" />
                                            <div className="mt-0">
                                                <label htmlFor="filter-assignment" className="form-label service-form-label">Filter by Assignment</label>
                                                <select className="form-select" id="filter-assignment" value={filters.assignment} onChange={(e) => handleOnChangeFiltersReports(e)}>
                                                    <option value="">All</option>
                                                    {allPeopleLocationsList.map((item) =>
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <hr className="mt-4" />
                                            <div className="mt-0">
                                                <label htmlFor="filter-assignment" className="form-label service-form-label">Filter by Asset Type</label>
                                                <select className="form-select" id="filter-asset-type" value={filters.assetType} onChange={(e) => handleOnChangeFiltersReports(e)}>
                                                    <option value="">All</option>
                                                    {assetTypeList.map((assetType) =>
                                                        <option value={assetType.AssetType_ID} key={assetType.AssetType_ID}>{assetType.AssetType_VC}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <hr className="mt-4" />
                                            <div className="mt-2">
                                                <label htmlFor="labelPurchaseStatus" className="form-label service-form-label pe-2">Filter by Purchase Status:</label>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="filterByPurchaseStatus" id="filter-purchase-status" value="" onChange={(e) => handleRadioFilters(e)} checked={filters.purchaseStatus === ""} />
                                                    <label className="form-check-label service-check-label" htmlFor="filterByComplete">All</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="filterByPurchaseStatus" id="filter-purchase-status" value="1" onChange={(e) => handleRadioFilters(e)} checked={filters.purchaseStatus === "1"} />
                                                    <label className="form-check-label service-check-label" htmlFor="filterByComplete">Complete</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="filterByPurchaseStatus" id="filter-purchase-status" value="0" onChange={(e) => handleRadioFilters(e)} checked={filters.purchaseStatus === "0"} />
                                                    <label className="form-check-label service-check-label" htmlFor="filterByOpen">Open</label>
                                                </div>
                                            </div>
                                            <hr className="mt-4" />
                                            <div className="mt-2">
                                                <label htmlFor="labelActiveStatus" className="form-label service-form-label pe-2">Filter by Active Status:</label>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="filterByActiveStatus" id="filter-active-status" value="" onChange={(e) => handleRadioFilters(e)} checked={filters.activeStatus === ""} />
                                                    <label className="form-check-label service-check-label" htmlFor="filterByInactive">All</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="filterByActiveStatus" id="filter-active-status" value="1" onChange={(e) => handleRadioFilters(e)} checked={filters.activeStatus === "1"} />
                                                    <label className="form-check-label service-check-label" htmlFor="filterByActive">Active</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="filterByActiveStatus" id="filter-active-status" value="0" onChange={(e) => handleRadioFilters(e)} checked={filters.activeStatus === "0"} />
                                                    <label className="form-check-label service-check-label" htmlFor="filterByInactive">Inactive</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button type="button" className="button-max-secondary" onClick={() => setFilters(filtersUnedited)} data-bs-dismiss="modal">Cancel</button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="button" className="button-max-primary" onClick={() => handleApplyFilters()} data-bs-dismiss="modal">Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="report-modal" aria-hidden="true" aria-labelledby="Choose Report" tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5">Choose Report</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="mt-0">
                                                <label htmlFor="report-type" className="form-label service-form-label"></label>
                                                <select className="form-select" id='report-type' value={selReportType} onChange={(e) => { handleOnChangeFiltersReports(e) }}>
                                                    <option value="" disabled>-- choose a report type --</option>
                                                    {reportTypes.map((report, index) =>
                                                        <option value={report} key={index}>{report}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <button type="button" className="button-max-secondary" data-bs-dismiss="modal" onClick={() => setSelReportType("")}>Cancel</button>
                                                    </div>
                                                    {selReportType && selReportType !== "" &&
                                                        <>
                                                            <div className="col-4">
                                                                <button type="button" className="button-max-primary" data-bs-toggle="modal" data-bs-target="#modalPrintPreview" onClick={() => handlePreviewReport()}>View</button>
                                                            </div>
                                                            <div className="col-4">
                                                                <button type="button" className="button-max-primary" data-bs-dismiss="modal" onClick={() => handleDownloadReport()}>Download</button>
                                                            </div>
                                                        </>
                                                    }
                                                    {(!selReportType || selReportType === "") &&
                                                        <>
                                                            <div className="col-4">
                                                                <button type="button" className="button-max-primary" disabled>View</button>
                                                            </div>
                                                            <div className="col-4">
                                                                <button type="button" className="button-max-primary" data-bs-dismiss="modal" disabled>Download</button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="uploadAssetPhoto" aria-hidden="true"
                                aria-labelledby="Upload Asset Photo Modal" tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5">Upload Asset Photo</h1>
                                            <button type="button" className="btn-close" data-bs-target="#uploadAssetPhoto"
                                                data-bs-toggle="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">

                                        </div>
                                        <div className="modal-footer">
                                            <div className="container">
                                                <div className="row">
                                                    {/* Mobile Buttons */}
                                                    <div className="col-6 mobile-card">
                                                        <button type="button" className="button-max-secondary"
                                                            data-bs-target="#mobile-view-modal" data-bs-toggle="modal">Cancel</button>
                                                    </div>
                                                    <div className="col-6 mobile-card">
                                                        <button type="button" className="button-max-primary"
                                                            data-bs-target="#mobile-view-modal" data-bs-toggle="modal">Save</button>
                                                    </div>
                                                    {/* Desktop Buttons */}
                                                    <div className="col-6 desktop-card">
                                                        <button type="button" className="button-max-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    </div>
                                                    <div className="col-6 desktop-card">
                                                        <button type="button" className="button-max-primary" data-bs-dismiss="modal">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="checkInAsset" aria-hidden="true"
                                aria-labelledby="Check In Asset" tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5">Check In Asset</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close" onClick={() => handleCancelAssignment()}></button>
                                        </div>
                                        <div className="modal-body">
                                            {/* <div className="row">
                                                <div className="col-6">
                                                    <div className="mt-1">
                                                        <label htmlFor="current-assignee" className="form-label service-form-label">Current Assignee</label>
                                                        <input type="text" className="form-control" id="vendor" value={selectedAssetInformation.assignedTo} readOnly={true} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mt-1">
                                                        <label htmlFor="check-in-date" className="form-label service-form-label">Check-In Date</label>
                                                        <input type="date" className="form-control" id="check-in-date"
                                                            value={selectedAssetInformation.checkInDate} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mt-1">
                                                        <label htmlFor="new-assignee-in" className="form-label service-form-label">Manager or Location</label>
                                                        <select className="form-select" id='new-assignee-in' value={selectedAssetInformation.newAssignment.assigneeID} onChange={(e) => { handleOnChangeSelAssetInfo(e) }}>
                                                            <option value="" disabled></option>
                                                            {checkInList.map((item) =>
                                                                <option value={item.id} key={item.id}>{item.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mt-1">
                                                        <label htmlFor="check-in-date" className="form-label service-form-label">Check In Date</label>
                                                        <input type="date" className="form-control" id="check-in-date"
                                                            value={selectedAssetInformation.newAssignment.checkInDate} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mt-1">
                                                        <label htmlFor="check-in-condition" className="form-label service-form-label">Condition</label>
                                                        <select className="form-select" id='check-in-condition' value={selectedAssetInformation.newAssignment.checkInCondition} onChange={(e) => { handleOnChangeSelAssetInfo(e) }}>
                                                            <option value="" disabled></option>
                                                            {conditionsList.map((condition) =>
                                                                <option value={condition.Condition_ID} key={condition.Condition_ID}>{condition.Condition_VC}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mt-1">
                                                    <label htmlFor="check-in-notes" className="form-label service-form-label">Notes</label>
                                                    <textarea className="form-control" id="check-in-notes" maxLength={250}
                                                        value={selectedAssetInformation.newAssignment.checkInNotes} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="container">
                                                <div className="row">
                                                    {/* Mobile Buttons */}
                                                    <div className="col-6 mobile-card">
                                                        <button type="button" className="button-max-secondary"
                                                            data-bs-target="#mobile-view-modal" data-bs-toggle="modal" onClick={() => handleCancelAssignment("In")}>Cancel</button>
                                                    </div>
                                                    <div className="col-6 mobile-card">
                                                        <button type="button" className="button-max-primary"
                                                            data-bs-target="#mobile-view-modal" data-bs-toggle="modal" onClick={() => handleSaveAssignment("In")}>Save</button>
                                                    </div>
                                                    {/* Desktop Buttons */}
                                                    <div className="col-6 desktop-card">
                                                        <button type="button" className="button-max-secondary" data-bs-dismiss="modal" onClick={() => handleCancelAssignment("In")}>Cancel</button>
                                                    </div>
                                                    <div className="col-6 desktop-card">
                                                        <button type="button" className="button-max-primary" data-bs-dismiss="modal" onClick={() => handleSaveAssignment("In")}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="checkOutAsset" aria-hidden="true"
                                aria-labelledby="Check Out Asset" tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5">Check Out Asset</h1>
                                            <button type="button" className="btn-close"
                                                data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCancelAssignment()}></button>
                                        </div>
                                        <div className="modal-body">
                                            {/* <div className="row">
                                                <div className="col-6">
                                                    <div className="mt-1">
                                                        <label htmlFor="current-assignee" className="form-label service-form-label">Current Assignee</label>
                                                        <input type="text" className="form-control" id="vendor" value={selectedAssetInformation.assignedTo} readOnly={true} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mt-1">
                                                        <label htmlFor="check-in-date" className="form-label service-form-label">Check-In Date</label>
                                                        <input type="date" className="form-control" id="check-in-date"
                                                            value={selectedAssetInformation.checkInDate} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mt-1">
                                                        <label htmlFor="new-assignee" className="form-label service-form-label">Employee or Location</label>
                                                        <select className="form-select" id='new-assignee-out' value={selectedAssetInformation.newAssignment.assigneeID} onChange={(e) => { handleOnChangeSelAssetInfo(e) }}>
                                                            <option value="" disabled></option>
                                                            {assigneeList.map((assignee) =>
                                                                <option value={assignee.id} key={assignee.id}>{assignee.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mt-1">
                                                        <label htmlFor="check-out-date" className="form-label service-form-label">Check Out Date</label>
                                                        <input type="date" className="form-control" id="check-out-date"
                                                            value={selectedAssetInformation.newAssignment.checkOutDate} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mt-1">
                                                        <label htmlFor="check-out-condition" className="form-label service-form-label">Condition</label>
                                                        <select className="form-select" id='check-out-condition' value={selectedAssetInformation.newAssignment.checkOutCondition} onChange={(e) => { handleOnChangeSelAssetInfo(e) }}>
                                                            <option value="" disabled></option>
                                                            {conditionsList.map((condition) =>
                                                                <option value={condition.Condition_ID} key={condition.Condition_ID}>{condition.Condition_VC}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mt-1">
                                                    <label htmlFor="check-out-notes" className="form-label service-form-label">Notes</label>
                                                    <textarea className="form-control" id="check-out-notes" maxLength={250}
                                                        value={selectedAssetInformation.newAssignment.checkOutNotes} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="container">
                                                <div className="row">
                                                    {/* Mobile Buttons */}
                                                    <div className="col-6 mobile-card">
                                                        <button type="button" className="button-max-secondary"
                                                            data-bs-target="#mobile-view-modal" data-bs-toggle="modal" onClick={() => handleCancelAssignment("Out")}>Cancel</button>
                                                    </div>
                                                    <div className="col-6 mobile-card">
                                                        <button type="button" className="button-max-primary"
                                                            data-bs-target="#mobile-view-modal" data-bs-toggle="modal" onClick={() => handleSaveAssignment("Out")}>Save</button>
                                                    </div>
                                                    {/* Desktop Buttons */}
                                                    <div className="col-6 desktop-card">
                                                        <button type="button" className="button-max-secondary" data-bs-dismiss="modal" onClick={() => handleCancelAssignment("Out")}>Cancel</button>
                                                    </div>
                                                    <div className="col-6 desktop-card">
                                                        <button type="button" className="button-max-primary" data-bs-dismiss="modal" onClick={() => handleSaveAssignment("Out")}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade" id="mobile-view-modal" aria-hidden="true" aria-labelledby="Mobile View" tabIndex="-1" >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ "position": "right" }} onClick={() => handleCancel()}></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="mt-0">
                                                {selectedAssetInformation &&
                                                    <EditAssetView
                                                        newImageB64={newImageB64}
                                                        fileInput={fileInput}
                                                        loadingSave={loadingSave}
                                                        divisionList={divisionList}
                                                        peopleList={peopleList}
                                                        managerList={managerList}
                                                        assetTypeList={assetTypeList}
                                                        vendorList={vendorList}
                                                        selectedAssetInformation={selectedAssetInformation}
                                                        pendingChanges={pendingChanges}
                                                        handleOnChangeSelAssetInfo={handleOnChangeSelAssetInfo}
                                                        handleRadioSelAsset={handleRadioSelAsset}
                                                        handleRemovePhoto={handleRemovePhoto}
                                                        handleCancel={handleCancel}
                                                        handleSave={handleSave}
                                                        mobile={true}
                                                        loadingCard={loadingCard}
                                                        handleTakePhoto={handleTakePhoto}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal" id="modalPrintPreview">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">

                                        <div className="modal-header">
                                            <h4 className="modal-title">View Report</h4>
                                            <button type="button" className="btn-close" data-bs-target="#report-modal"
                                                data-bs-toggle="modal"></button>
                                        </div>

                                        <div className="modal-body">
                                            <div width="100%" className="pdfPreview">
                                                {loadingPdfPreview &&
                                                    <Spinner className="spinner"></Spinner>
                                                }
                                                {!loadingPdfPreview &&
                                                    <embed src={pdf} type='application/pdf' width="100%" height="550"></embed>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal show={show} id="save-changes-modal" aria-hidden="true" aria-labelledby="Save Changes?" tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered save-changes">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5">Save Changes?</h1>
                                            <button type="button" className="btn-close" onClick={() => handleClose()}></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="mt-0">
                                                <span>You have changes pending.</span>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="container">
                                                <div className="row">
                                                    {/* Mobile Buttons */}
                                                    <div className="col-6 mobile-card">
                                                        <button type="button" className="button-max-secondary"
                                                            data-bs-target="#mobile-view-modal" data-bs-toggle="modal" onClick={() => handleCancel()}>Cancel</button>
                                                    </div>
                                                    <div className="col-6 mobile-card">
                                                        <button type="button" className="button-max-primary"
                                                            data-bs-target="#mobile-view-modal" data-bs-toggle="modal" onClick={() => handleSave()}>Save</button>
                                                    </div>
                                                    {/* Desktop Buttons */}
                                                    <div className="col-6 desktop-card">
                                                        <button type="button" className="button-max-secondary" data-bs-dismiss="modal" onClick={() => handleCancel()}>Cancel</button>
                                                    </div>
                                                    <div className="col-6 desktop-card">
                                                        <button type="button" className="button-max-primary" data-bs-dismiss="modal" onClick={() => handleSave()}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="list-group-scroll-reports">
                                <ul className="list-group" id="listPeople" style={{ "marginBlockEnd": "200px" }}>
                                    {!loadingAssets && filteredAssets && filteredAssets.length !== 0 && filteredAssets.map((asset, index) =>

                                        < Card
                                            className={(selCardIndex === asset.Asset_ID) ? "list-group-item item-hilight active" : "list-group-item item-hilight"}
                                            key={asset.Asset_ID}
                                            onClick={() => handleUpdateSelectedAsset(asset)}
                                        // style={{ background: selectedCard.ReportId === data.ReportId ? "#a1d6f7" : "#ffffff", }}
                                        >
                                            <div data-bs-toggle="modal" data-bs-target="#mobile-view-modal" className="mobile-card">
                                                <div className="d-flex flex-row flex-wrap justify-content-between">
                                                    <div><span className="asset-name">{asset.Description_VC}</span></div>

                                                </div>
                                                <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                                    <div><span className="asset-label">Purchase Date: </span><span className="asset-text">{asset.PurchaseDate_DT}</span></div>
                                                    <div><span className="asset-label">Serial No: </span><span className="asset-text">{asset.SerialNumber_VC}</span></div>
                                                </div>
                                                <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                                    <div><span className="asset-label">Assigned To: </span><span className="asset-text">{asset.AssignedTo}</span></div>
                                                    <div><span className="asset-label">Check-Out Date: </span><span className="asset-text">{asset.CheckDate_DT}</span></div>
                                                </div>
                                            </div>
                                            <div className="desktop-card">
                                                <div className="d-flex flex-row flex-wrap justify-content-between">
                                                    <div><span className="asset-name">{asset.Description_VC}</span></div>

                                                </div>
                                                <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                                    <div><span className="asset-label">Purchase Date: </span><span className="asset-text">{asset.PurchaseDate_DT}</span></div>
                                                    <div><span className="asset-label">Serial No: </span><span className="asset-text">{asset.SerialNumber_VC}</span></div>
                                                </div>
                                                <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                                    <div><span className="asset-label">Assigned To: </span><span className="asset-text">{asset.AssignedTo}</span></div>
                                                    <div><span className="asset-label">Check-Out Date: </span><span className="asset-text">{asset.CheckDate_DT}</span></div>
                                                </div>
                                            </div>
                                        </Card>
                                    )}

                                    {!loadingAssets && (!filteredAssets || filteredAssets.length === 0) &&
                                        <div>No assets found with current filters</div>
                                    }

                                    {loadingAssets &&
                                        <div style={{ "textAlign": "center" }}>
                                            <Spinner className="spinner"></Spinner>
                                        </div>
                                    }
                                </ul>
                            </div>

                        </div>
                        <div className="col-sm-8 d-none d-sm-block p-1">
                            <div style={{ "height": "100%", "width": "100%" }}>
                                {loadingCard &&
                                    <div style={{ "textAlign": "center" }}>
                                        <Spinner className="spinner"></Spinner>
                                    </div>
                                }

                                {!loadingCard && selectedAssetInformation && (selectedAssetInformation.assetID || creatingNewAsset) &&
                                    <EditAssetView
                                        divisionList={divisionList}
                                        peopleList={peopleList}
                                        managerList={managerList}
                                        assetTypeList={assetTypeList}
                                        vendorList={vendorList}
                                        selectedAssetInformation={selectedAssetInformation}
                                        newImageB64={newImageB64}
                                        pendingChanges={pendingChanges}
                                        handleOnChangeSelAssetInfo={handleOnChangeSelAssetInfo}
                                        handleRadioSelAsset={handleRadioSelAsset}
                                        handleTakePhoto={handleTakePhoto}
                                        handleRemovePhoto={handleRemovePhoto}
                                        handleCancel={handleCancel}
                                        handleSave={handleSave}
                                        mobile={false}
                                        fileInput={fileInput}
                                        loadingSave={loadingSave}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default AssetManagerInterface;