import { Spinner } from 'react-bootstrap';
import AssetPlaceholder from '../../../svg/AssetPlaceholder.svg'


import '../../../styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const EditAssetView = (props) => {

    const {
        divisionList,
        peopleList,
        managerList,
        assetTypeList,
        vendorList,
        selectedAssetInformation,
        newImageB64,
        pendingChanges,
        handleOnChangeSelAssetInfo,
        handleRadioSelAsset,
        handleRemovePhoto,
        handleCancel,
        handleSave,
        mobile,
        fileInput,
        loadingSave,
        loadingCard,
        handleTakePhoto
    } = props;

    return (
        <>
            <div className="container-fluid px-2" style={{ "marginTop": "10px" }}>
                <div className="card mx-auto">
                    <div className="card-body">
                        {loadingCard &&
                            <div className='spinner-mobile'>
                                <Spinner />
                            </div>
                        }
                        {!loadingCard &&
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="service-subheading mt-2 mb-2">
                                        Asset Information
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="purchase-date" className="form-label service-form-label">Purchase Date</label>
                                        <input type="date" className="form-control left" id="purchase-date" value={selectedAssetInformation.purchaseDate} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                    </div>
                                    {/* <div className="mt-1">
                                    <label htmlFor="vendor" className="form-label service-form-label">Vendor</label>
                                    <input type="text" className="form-control" id="vendor" value={selectedAssetInformation.vendor} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                </div> */}
                                    <div className="mt-0">
                                        <label htmlFor="vendor" className="form-label service-form-label">Vendor</label>
                                        <select className="form-select" id='vendor' value={selectedAssetInformation.vendor} onChange={(e) => handleOnChangeSelAssetInfo(e)}>
                                            <option value="" disabled></option>
                                            {vendorList.map((vendor) =>
                                                <option value={vendor.Vendor_ID} key={vendor.Vendor_ID}>{vendor.Vendor_VC}</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="mt-1">
                                        <label htmlFor="asset-description" className="form-label service-form-label">Asset Description</label>
                                        <input type="text" className="form-control" id="asset-description" maxLength={250} value={selectedAssetInformation.assetDescription} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="asset-type" className="form-label service-form-label">Asset Type</label>
                                        <select className="form-select" id="asset-type" value={selectedAssetInformation.assetType} onChange={(e) => { handleOnChangeSelAssetInfo(e) }}>
                                            <option value="" disabled></option>
                                            {assetTypeList.map((type) =>
                                                <option value={type.AssetType_ID} key={type.AssetType_ID}>{type.AssetType_VC}</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="mt-1">
                                        <label htmlFor="cost" className="form-label service-form-label">Cost</label>
                                        <input type="text" className="form-control" id="cost"
                                            value={selectedAssetInformation.cost} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="serial-number" className="form-label service-form-label">Serial Number</label>
                                        <input type="text" className="form-control" id="serial-number" maxLength={50}
                                            value={selectedAssetInformation.serialNumber} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="invoice-number" className="form-label service-form-label">Invoice Number</label>
                                        <input type="text" className="form-control" id="invoice-number" maxLength={50}
                                            value={selectedAssetInformation.invoiceNumber} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="po-number" className="form-label service-form-label">PO Number</label>
                                        <input type="text" className="form-control" id="po-number" maxLength={50}
                                            value={selectedAssetInformation.PONumber} onChange={(e) => handleOnChangeSelAssetInfo(e)} />
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="purchased-division" className="form-label service-form-label">Division</label>
                                        <select className="form-select" value={selectedAssetInformation.division} id="division" onChange={(e) => handleOnChangeSelAssetInfo(e)}>
                                            <option value="" disabled>-- select an option --</option>
                                            {divisionList.map((division) =>
                                                <option key={division.Division_ID} value={division.Division_ID}>{division.Division_ID} - {division.Division_VC}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="labelPurchaseStatus" className="form-label service-form-label pe-2">Purchase Status:</label>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name={mobile ? "inlinePurchaseStatusMobile" : "inlinePurchaseStatus"} id="purchaseStatus" value="1" onChange={(e) => handleRadioSelAsset(e)} checked={selectedAssetInformation.purchaseStatus === "1" || selectedAssetInformation.purchaseStatus === true || selectedAssetInformation.purchaseStatus === 1 || selectedAssetInformation.purchaseStatus === "true"} />
                                            <label className="form-check-label service-check-label"
                                                htmlFor="statusPurchaseComplete">Complete</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name={mobile ? "inlinePurchaseStatusMobile" : "inlinePurchaseStatus"} id="purchaseStatus" value="0" onChange={(e) => handleRadioSelAsset(e)} checked={selectedAssetInformation.purchaseStatus === "0" || selectedAssetInformation.purchaseStatus === false || selectedAssetInformation.purchaseStatus === 0 || selectedAssetInformation.purchaseStatus === "false"} />
                                            <label className="form-check-label service-check-label"
                                                htmlFor="statusPurchaseOpen">Open</label>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="purpose" className="form-label service-form-label">Purpose</label>
                                        <input type="text" className="form-control" id="purpose" maxLength={250}
                                            value={selectedAssetInformation.purpose} onChange={(e) => handleOnChangeSelAssetInfo(e)}></input>
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="purchased-by" className="form-label service-form-label" >Purchased By</label>
                                        <select className="form-select" id="purchased-by" value={selectedAssetInformation.purchasedBy} onChange={(e) => handleOnChangeSelAssetInfo(e)}>
                                            <option value="" disabled></option>
                                            {peopleList.map((person) =>
                                                <option value={person.People_ID} key={person.People_ID}>{person.LastName_VC}, {person.FirstName_VC}</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="mt-1">
                                        <label htmlFor="arroved-by" className="form-label service-form-label" >Approved By</label>
                                        <select className="form-select" id="approved-by" value={selectedAssetInformation.approvedBy} onChange={(e) => handleOnChangeSelAssetInfo(e)}>
                                            <option value="" disabled></option>
                                            {managerList.map((person) =>
                                                <option value={person.People_ID} key={person.People_ID}>{person.LastName_VC}, {person.FirstName_VC}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-4 pb-4">
                                        <div className="service-subheading mt-2">Upload Photo</div>
                                        <div className="row g-0 mt-2 justify-content-evenly">
                                            <div className="col-6">
                                                {/* {!mobile &&
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept="image/*" // Specify accepted file types here, e.g., "image/*" for images
                                                            style={{ display: 'none' }}
                                                            ref={fileInput}
                                                            onChange={() => handleSetNewPhoto()}
                                                        />
                                                        {(newImageB64 || selectedAssetInformation.photo !== "") &&
                                                            <div><button className="button-pill-small" onClick={() => fileInput.current.click()}>Change Photo</button></div>
                                                        }
                                                        {(!newImageB64 || newImageB64 === undefined || newImageB64 === null) && selectedAssetInformation.photo === "" &&
                                                            <div><button className="button-pill-small" onClick={() => fileInput.current.click()}>Choose Photo</button></div>
                                                        }
                                                    </>
                                                } */}
                                                <>
                                                    <label htmlFor="capture-button">
                                                        {!mobile && (newImageB64 || selectedAssetInformation.photo !== "") &&
                                                            <div className="button-pill-small" >
                                                                Change Photo
                                                            </div>
                                                        }
                                                        {!mobile && (!newImageB64 || newImageB64 === undefined || newImageB64 === null) && selectedAssetInformation.photo === "" &&
                                                            <div className="button-pill-small" >
                                                                Choose Photo
                                                            </div>
                                                        }
                                                        {mobile && (newImageB64 || selectedAssetInformation.photo !== "") &&
                                                            <div className="button-pill-small" >
                                                                Retake Photo
                                                            </div>
                                                        }
                                                        {mobile && (!newImageB64 || newImageB64 === undefined || newImageB64 === null) && selectedAssetInformation.photo === "" &&
                                                            <div className="button-pill-small" >
                                                                Take Photo
                                                            </div>
                                                        }


                                                        <input
                                                            accept="image/*,image/heif"
                                                            capture
                                                            hidden
                                                            id="capture-button"
                                                            // key={fileInputKey}
                                                            ref={fileInput}
                                                            onChange={e => handleTakePhoto(e)}
                                                            type="file"
                                                        />
                                                    </label>


                                                </>

                                                <div><button className="button-pill-small-caution" onClick={() => handleRemovePhoto()}>Remove Photo</button></div>
                                            </div>
                                            <div className="col-6">

                                                <div><img alt='' src={selectedAssetInformation.photo || AssetPlaceholder} width="125px" /></div>
                                            </div>
                                        </div>

                                    </div>
                                    <hr />

                                    <div className="service-subheading pt-4 mt-4 mb-2">
                                        Assignment Information
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor="prefPrimaryAccount" className="form-label service-form-label">Assignment Log</label>
                                        <textarea className="form-control assignment-log-text" readOnly id="assignment-log" rows="8" value={selectedAssetInformation.assignmentLog} onChange={(e) => handleOnChangeSelAssetInfo(e)}></textarea>
                                    </div>
                                    <div className="mt-2 mb-4 pb-4">
                                        {selectedAssetInformation && selectedAssetInformation.newAssignment.assignmentDirection === "" &&
                                            < div >
                                                <button className="button-pill-small" data-bs-target="#checkInAsset" data-bs-toggle="modal">Check In</button>
                                                <button className="button-pill-small" data-bs-target="#checkOutAsset" data-bs-toggle="modal">Check Out</button>
                                            </div>
                                        }
                                        {selectedAssetInformation && selectedAssetInformation.newAssignment.assignmentDirection === "In" &&
                                            <div>
                                                <button className="button-pill-small" data-bs-target="#checkInAsset" data-bs-toggle="modal">Check In</button>
                                                <button className="button-pill-small" data-bs-target="#checkOutAsset" data-bs-toggle="modal" disabled>Check Out</button>
                                            </div>
                                        }
                                        {selectedAssetInformation && selectedAssetInformation.newAssignment.assignmentDirection === "Out" &&
                                            <div>
                                                <button className="button-pill-small" data-bs-target="#checkInAsset" data-bs-toggle="modal" disabled>Check In</button>
                                                <button className="button-pill-small" data-bs-target="#checkOutAsset" data-bs-toggle="modal">Check Out</button>
                                            </div>
                                        }
                                    </div>

                                    <hr />
                                    <div className="service-subheading pt-4 mt-4 mb-0">
                                        Asset Use Status
                                    </div>

                                    <div className="mt-2">
                                        <label htmlFor="labelUseStatus" className="form-label service-form-label pe-2">Asset Use Status:</label>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name={mobile ? "inlineUseStatusMobile" : "inlineUseStatus"} id="useStatus" value="1" onChange={(e) => handleRadioSelAsset(e)} checked={selectedAssetInformation.useStatus === "1"} />
                                            <label className="form-check-label service-check-label"
                                                htmlFor="useStatus">Active</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name={mobile ? "inlineUseStatusMobile" : "inlineUseStatus"} id="useStatus" value="0" onChange={(e) => handleRadioSelAsset(e)} checked={selectedAssetInformation.useStatus === "0"} />
                                            <label className="form-check-label service-check-label"
                                                htmlFor="useStatus">Inactive</label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="pt-4 mt-4">

                                            <div className="row">
                                                {mobile &&
                                                    <>
                                                        <div className="col-6">
                                                            <button type="button" className="button-max-secondary" onClick={() => handleCancel()} data-bs-dismiss="modal">Cancel</button>
                                                        </div>
                                                        <div className="col-6">
                                                            <button type="button" className={pendingChanges ? "button-max-primary" : "button-max-secondary"} onClick={() => handleSave()}>{loadingSave ? <Spinner style={{ "color": "black" }} /> : "Save"}</button>
                                                        </div>
                                                    </>
                                                }
                                                {!mobile &&
                                                    <>
                                                        <div className="col-6">
                                                            <button type="button" className="button-max-secondary" onClick={() => handleCancel()}>Cancel</button>
                                                        </div>
                                                        <div className="col-6">
                                                            <button type="button" className={pendingChanges ? "button-max-primary" : "button-max-secondary"} onClick={() => handleSave()}>{loadingSave ? <Spinner style={{ "color": "black" }} /> : "Save"}</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditAssetView;