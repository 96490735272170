import React from "react";
import AssetManagerInterface from "./AssetManagerInterface";
import { Navigate } from "react-router-dom";
import { getAccessToken, getEmail } from '../../services/User';

// const tabs = [
//     {
//         key: 'assetManager',
//         label: 'Asset Manager'
//     }
// ];

const AssetManager = () => {
    // const [activeTab, setActiveTab] = useState('assetManager');
    const activeTab = 'assetManager'
    const token = getAccessToken();
    const userEmail = getEmail();
    console.log(userEmail)

    if (token === null || token === '') {
        return <Navigate to='/login' />
    }

    return (
        <div>
            {activeTab === 'assetManager' && (
                <AssetManagerInterface />
            )}
        </div>
    )
}

export default AssetManager;